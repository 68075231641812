import React from 'react';

import { Container, Header, Icon } from 'semantic-ui-react';
import Layout from '../components/layout/layout';
import { Link } from 'gatsby';

const Success: React.FC = () => {
  return (
    <Layout>
      <Container text style={{ marginTop: 50 }}>
        <Container textAlign="center">
          <Icon name="smile outline" fitted size="massive" />
        </Container>
        <Header as="h1" textAlign="center">
          お問い合わせありがとうございました。
        </Header>
        <Container textAlign="center">
          <Link to="/">Home</Link>
        </Container>
      </Container>
    </Layout>
  );
};

export default Success;
