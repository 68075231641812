import React from 'react';
import { Container } from 'semantic-ui-react';
import Layout from '../components/layout/layout';
import { Card, Image } from 'semantic-ui-react';

import { graphql } from 'gatsby';
import SEO from '../components/seo';

import './about-me.css';

export const query = graphql`
  query {
    madscatter: file(base: { eq: "madscatter_small.png" }) {
      publicURL
      childImageSharp {
        fluid {
          base64
          src
          srcSet
          srcSetWebp
          tracedSVG
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    portfolio: file(base: { eq: "Portfolio.svg" }) {
      publicURL
      childImageSharp {
        fluid {
          base64
          src
          srcSet
          srcSetWebp
          tracedSVG
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

interface Props {
  data: {
    madscatter: {
      publicURL: string;
    };
    portfolio: {
      publicURL: string;
    };
  };
}

const aboutMe: React.FC<Props> = (data: Props) => {
  return (
    <Layout>
      <SEO title="870Labo" />
      <Container>
        <div
          style={{
            marginTop: 50,
            // width: 400,
            marginBottom: 50,
          }}
        >
          <Card centered className={'about-me-card-width'} raised>
            <Card
              centered
              style={{ marginTop: 15, width: 240, borderRadius: '50%' }}
            >
              <Image
                src={data.data.madscatter.publicURL}
                // circular
              />
            </Card>
            <Card.Content>
              <Card.Header>塙 剛志</Card.Header>
              <Card.Meta>Software Engineer</Card.Meta>
              <Card.Description>
                <p>ソフトウェア開発歴２０年以上。</p>
                <p>
                  もともとはオーディオ機器関連のコンピューター解析を行っていたが、「闘うプログラマー」を読んで『ソフトウェア開発は面白い！』と感じソフトウェア業界へ転身する。
                </p>
                <p>
                  転身後は自らソフトウェア製品を企画し開発も行うというスタイルをとる。
                </p>
                <p>
                  当時はWindowsプラットフォームで「OpenGLを用いた３Dモデラー」・「メガデモ系メールソフト」・「メモリダイヤル編集ソフト」等を開発する。
                </p>
                <p>
                  その後、WindowsCEプラットフォームでのカーナビアプリの開発に携わり、iOSプラットフォームでのカーナビアプリもリリースした。
                </p>
                <p>iOSのアプリは、iTunes Rewindに選出された。</p>
                <p>
                  iOSでアプリを作っているときに、ネットワーク接続を前提としたアプリケーションの面白さに目覚め、Webアプリケーションの開発も行うようになる。
                </p>
                <p>
                  主な開発ターゲットをローカルアプリケーションからWebアプリケーションへと変え、現在では完全にWeb開発の方へ軸足を移した。
                </p>
                <p>
                  Webアプリケーション開発では、クライアントからサーバーまでフルスタックにこなす。
                </p>
                <p>
                  ただしグラフィックデザインはそれほど得意ではないので、BootstrapやSemantic
                  UI等を活用しながら開発を行っている。
                </p>
                <p>
                  今まで行ってきた事の概要を挙げると、以下のような感じとなる。
                </p>
                <Image
                  src={data.data.portfolio.publicURL}
                  className={'about-me-portfolio'}
                />
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      </Container>
    </Layout>
  );
};

export default aboutMe;
