/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import SlideMenuBar from '../menu/SlideMenuBar';

// import Header from "./header"
// import "./layout.css"

interface Prop {
  children: React.ReactNode;
}

const Layout: React.FC<Prop> = ({ children }) => {
  return (
    <React.Fragment>
      <SlideMenuBar />
      {children}
    </React.Fragment>
  );
};

export default Layout;
