/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require("prismjs/themes/prism-solarizedlight.css");
// require("prismjs/themes/prism-coy.css");
// require("prismjs/themes/prism-dark.css");
require('prismjs/themes/prism-okaidia.css');
// require("prismjs/plugins/line-numbers/prism-line-numbers.css");

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is pollyfilled!`);
  }
};
