import React from 'react';

import { Container, Form, Button, Header } from 'semantic-ui-react';
import Layout from '../components/layout/layout';

const Contact: React.FC = () => {
  return (
    <Layout>
      <Container text>
        <Header as="h1" style={{ marginTop: 50 }} textAlign="center">
          お問い合わせ
        </Header>
        <Form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/success"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Form.Field>
            <label>お名前</label>
            <input type="text" placeholder="Your Name" name="name" />
          </Form.Field>
          <Form.Field>
            <label>メールアドレス</label>
            <input type="email" placeholder="Your EMail" name="email" />
          </Form.Field>
          <Form.Field>
            <label>メッセージ</label>
            <textarea name="message" rows={10} />
          </Form.Field>
          <Button type="submit">送信</Button>
        </Form>
      </Container>
    </Layout>
  );
};

export default Contact;
