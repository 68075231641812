import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  // Container,
  Grid,
  Divider,
  Label,
  Icon,
  Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';
import './blog.css';
import Layout from '../components/layout/layout';

import { TocContext } from '../contexts/TocContext';

import { renderAst } from './renderAst';

import Toc from './Toc';
import SEO from '../components/seo';

// import TreeToc from './TreeToc';

const BlogTitle = styled.h1`
  // margin-top: 160px;
  padding-top: 1em;
`;

const BlogAttrs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PostPager = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const BlogPost = ({ data, pageContext }: any) => {
  const [currentHeader, setCurrentHeader] = React.useState('');

  const post = data.markdownRemark;
  const htmlAst = post.htmlAst;
  const { tags, title, keywords, description } = post.frontmatter;

  return (
    <TocContext.Provider value={{ id: currentHeader, func: setCurrentHeader }}>
      <Layout>
        <SEO title={title} description={description} keywords={keywords} />
        <Grid
          columns={3}
          centered
          stackable
          // divided
        >
          <Grid.Column width={4} only={'computer'}>
            <Toc
              htmlAst={htmlAst}
              headers={pageContext.headers}
              treeToc={pageContext.treeToc}
              parentMap={pageContext.parentMap}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            {/*<Container text>*/}
            <div className="blog">
              <BlogTitle>{post.frontmatter.title}</BlogTitle>
              <Divider />
              <BlogAttrs>
                <div>
                  {tags &&
                    tags.map((tag: string, id: number) => (
                      <Label
                        as="a"
                        href={`/tags/${tag.toLowerCase()}/`}
                        key={id}
                        color="orange"
                      >
                        <Icon name="tag" />
                        {tag}
                      </Label>
                    ))}
                </div>
                <div>
                  <Label>
                    <Icon name="calendar alternate outline" />
                    {post.frontmatter.date}
                  </Label>
                </div>
              </BlogAttrs>
              <Divider />
              {renderAst(htmlAst)}
            </div>
            <Divider />
            {/*<Grid>*/}
            {/*  <Grid.Column floated="left" width={2}>*/}
            {/*    {pageContext.next ? (*/}
            {/*      <Link to={`/posts${pageContext.next.slug}`}>Next</Link>*/}
            {/*    ) : (*/}
            {/*      ' '*/}
            {/*    )}*/}
            {/*  </Grid.Column>*/}
            {/*  <Grid.Column floated="right" width={2}>*/}
            {/*    {pageContext.prev ? (*/}
            {/*      <Link to={`/posts${pageContext.prev.slug}`}>Prev</Link>*/}
            {/*    ) : (*/}
            {/*      ' '*/}
            {/*    )}*/}
            {/*  </Grid.Column>*/}
            {/*</Grid>*/}
            <PostPager>
              <div>
                {pageContext.next ? (
                  <Popup
                    content={pageContext.next.title}
                    header="次のページ"
                    trigger={
                      <Link to={`/posts${pageContext.next.slug}`}>Next</Link>
                    }
                  />
                ) : (
                  ' '
                )}
              </div>
              <div>
                {pageContext.prev ? (
                  <Popup
                    content={pageContext.prev.title}
                    header="前のページ"
                    trigger={
                      <Link to={`/posts${pageContext.prev.slug}`}>Prev</Link>
                    }
                  />
                ) : (
                  ' '
                )}
              </div>
            </PostPager>
            {/*</Container>*/}
          </Grid.Column>
          <Grid.Column width={4} only={'computer'}>
            {' '}
          </Grid.Column>
        </Grid>
      </Layout>
    </TocContext.Provider>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        tags
        keywords
        description
        date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default BlogPost;
