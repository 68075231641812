import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Container,
  Card,
  Label,
  Icon,
  Pagination,
  PaginationProps,
} from 'semantic-ui-react';
import SlideMenuBar from '../components/menu/SlideMenuBar';
import { navigate } from '@reach/router';

import SEO from '../components/seo';


// interface PaginationObject {
//   activePage: number;
// }

const posts: React.FC<PostQueryType> = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;

  const onPageChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    { activePage }: PaginationProps
  ): void => {
    const nextUrl = activePage === 1 ? `/posts/` : `/posts/${activePage}`;
    navigate(nextUrl);
  };

  return (
    <React.Fragment>
      <SEO title="870Labo" />
      <SlideMenuBar />

      <Container text>
        <h1>Recent Posts</h1>
        <h4>{data.allMarkdownRemark.totalCount}posts</h4>
        <Card.Group>
          {posts.map(({ node }: MDEdge) => (
            <Card fluid key={node.id}>
              <Card.Header as="h3" style={{ padding: '0.5rem' }}>
                <Link to={`/posts${node.fields.slug}`}>
                  {node.frontmatter.title}
                </Link>
                <Container textAlign="right">{node.frontmatter.date}</Container>
              </Card.Header>
              <Card.Content>{node.frontmatter.description}</Card.Content>
              {/*<Card.Description>{node.excerpt}</Card.Description>*/}
              <Card.Content extra>
                {node.frontmatter.tags.map((tag: string, id: number) => (
                  <Label
                    as="a"
                    href={`/tags/${tag.toLowerCase()}/`}
                    key={id}
                    color="orange"
                  >
                    <Icon name="tag" />
                    {tag}
                  </Label>
                ))}
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Container>
      <Container textAlign="center" style={{ marginTop: 40 }}>
        <Pagination
          totalPages={pageContext.numPages}
          activePage={pageContext.currentPage}
          onPageChange={onPageChange}
        />
      </Container>
    </React.Fragment>
  );
};

interface MDEdge {
  node: {
    fields: {
      slug: string;
    };
    id: string;
    frontmatter: {
      title: string;
      date: string;
      description: string;
      tags: string[];
    };
    excerpt: string;
  };
}

interface PostQueryType {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: MDEdge[];
    };
  };
  pageContext: {
    numPages: number;
    currentPage: number;
  };
}

export const query = graphql`
  query postsListQUery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { fields: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            description
            tags
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`;

export default posts;
