import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
// import { Container, Header } from 'semantic-ui-react';

import SlideMenuBar from '../menu/SlideMenuBar';

const BackGround = styled.header`
  ${() =>
    css`
      background: -moz-radial-gradient(left top, #4c7788, #225876, #1b1f2a);
      background: -webkit-radial-gradient(left top, #4c7788, #225876, #1b1f2a);
      background: radial-gradient(left top, #4c7788, #225876, #1b1f2a);
    `};
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: white;
  position: relative;
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  height: 60%;
  align-items: center;
  font-size: 200%;
  font-family: 'Kosugi+Maru';
  font-weight: 700;
  transform: translate(-wrem, 0);
  // z-index: 10;
`;

const imageQuery = graphql`
  query {
    desktop: file(base: { eq: "bg-tri-poly.jpg" }) {
      publicURL
      childImageSharp {
        fluid {
          base64
          src
          srcSet
          srcSetWebp
          tracedSVG
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const CustomHeader: React.FC = () => {
  return (
    <StaticQuery
      query={imageQuery}
      render={data => {
        return (
          <React.Fragment>
            <BackGround>
              <SlideMenuBar inverted />
              <Hero>870 Laboratory</Hero>
            </BackGround>
          </React.Fragment>
        );
      }}
    />
  );
};

export default CustomHeader;
