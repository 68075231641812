import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { Link } from 'gatsby';

interface NavMenuProps {
  inverted?: boolean;
}

const NavMenu: React.FC<NavMenuProps> = ({ inverted = false }) => {
  return (
    <Menu
      style={{ backgroundColor: 'inherit' }}
      inverted={inverted}
      // color="violet"
      // borderless
      // style={{borderRadius: 0}}
    >
      <Container>
        <Menu.Item position="right">
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/posts/">Blog</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/about-this-site">About This Site</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/about-me">About Me</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/contact">Contact</Link>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default NavMenu;
