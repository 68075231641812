import React from 'react';
import {
  graphql,
  // Link
} from 'gatsby';
import {
  Container,
  Grid,
  Divider,
  Label,
  Icon,
  // Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';
import './blog.css';
import Layout from '../components/layout/layout';

import { TocContext } from '../contexts/TocContext';

import { renderAst } from './renderAst';

import Toc from './Toc';
import SEO from '../components/seo';

// import TreeToc from './TreeToc';

const BlogTitle = styled.h1`
  // margin-top: 160px;
  padding-top: 1em;
`;

const BlogAttrs = styled.div`
  display: flex;
  justify-content: space-between;
`;

// const PostPager = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 10px;
// `;

const BlogPost = ({ data, pageContext }: any) => {
  const [currentHeader, setCurrentHeader] = React.useState('');

  const post = data.markdownRemark;
  const htmlAst = post.htmlAst;
  const { tags, title, keywords, description } = post.frontmatter;

  return (
    <TocContext.Provider value={{ id: currentHeader, func: setCurrentHeader }}>
      <Layout>
        <SEO title={title} description={description} keywords={keywords} />
        <Grid
          columns={3}
          centered
          // divided
        >
          <Grid.Column width={4} only={'computer'}>
            <Toc
              htmlAst={htmlAst}
              headers={pageContext.headers}
              treeToc={pageContext.treeToc}
              parentMap={pageContext.parentMap}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Container text>
              <div className="blog">
                <BlogTitle>{post.frontmatter.title}</BlogTitle>
                <Divider />
                <BlogAttrs>
                  <div>
                    {tags &&
                      tags.map((tag: string, id: number) => (
                        <Label
                          as="a"
                          href={`/tags/${tag.toLowerCase()}/`}
                          key={id}
                          color="orange"
                        >
                          <Icon name="tag" />
                          {tag}
                        </Label>
                      ))}
                  </div>
                  <div>
                    <Label>
                      <Icon name="calendar alternate outline" />
                      {post.frontmatter.date}
                    </Label>
                  </div>
                </BlogAttrs>
                <Divider />
                {renderAst(htmlAst)}
              </div>
              <Divider />
            </Container>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </Layout>
    </TocContext.Provider>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        tags
        keywords
        description
        date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default BlogPost;
