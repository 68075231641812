import React from 'react';
import NavMenu from './NavMenu';
import { Visibility, VisibilityEventData } from 'semantic-ui-react';

import styled from 'styled-components';

const MenuBar = styled.div`
  position: fixed;
  height: 44px;
  top: -44px;
  width: 100%;
  transition-duration: 0.2s;
  z-index: 1000;
  background-color: #003;
  //box-shadow: 10px 10px 10px rgba(0,0,0,0.5);
`;

interface SlideProps {
  fixedMenu?: boolean;
  inverted?: boolean;
}

const SlideMenuBar: React.FC<SlideProps> = ({ inverted = false }) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [menuTimer, setMenuTimer] = React.useState(0);

  const cleanup = () => {
    if (menuTimer) {
      clearTimeout(menuTimer);
      setMenuTimer(0);
    }
  };

  React.useEffect(() => {
    return () => {
      cleanup();
    };
  });
  // },[menuTimer]);

  const handleUpdate = (e: null, { calculations }: VisibilityEventData) => {
    const { direction, topVisible } = calculations;
    if (topVisible) {
      setMenuVisible(false);
      return;
    }
    if (menuVisible) {
      if (direction === 'down') {
        setMenuVisible(false);
      }
    } else {
      if (direction === 'up') {
        setMenuVisible(true);
        setMenuTimer(
          setTimeout(() => {
            setMenuVisible(false);
            cleanup();
          }, 5000)
        );
      }
    }
  };

  return (
    <React.Fragment>
      <MenuBar
        style={{ top: menuVisible ? 0 : -44, opacity: menuVisible ? 1.0 : 0 }}
      >
        <NavMenu inverted />
      </MenuBar>
      <Visibility onUpdate={handleUpdate}>
        <NavMenu inverted={inverted} />
      </Visibility>
    </React.Fragment>
  );
};

export default SlideMenuBar;
