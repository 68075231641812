import React from 'react';
import 'semantic-ui-css/semantic.min.css';
// import 'semantic-ui-css/semantic.css';
import SEO from './seo';
import './App.css';
import Header from './Header/Header';
import Skills from './Skills/Skills';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <SEO title="870Labo" />
      <Header />
      <Skills />
    </React.Fragment>
  );
};

export default App;
