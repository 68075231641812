import { SemanticICONS } from 'semantic-ui-react';

export interface ThemeProp {
  name: string;
  link: string;
}

export interface CategProp {
  iconName: SemanticICONS;
  name: string;
  content: string;
  themes: ThemeProp[];
}

export const categories: CategProp[] = [
  {
    name: 'サーバー側',
    iconName: 'server',
    content:
      'サービスを作るときにはPhoenixを使用しています。またプロトタイプを作成するときにはFirebaseも使用しています。',
    themes: [
      {
        name: 'Elixir',
        link: 'https://elixir-lang.org/',
      },
      {
        name: 'Phoenix',
        link: 'https://phoenixframework.org/',
      },
    ],
  },
  {
    name: 'クライアント側',
    iconName: 'browser',
    content:
      'アプリ側ではElmやReactを使用しています。画面の作成にはSemanticUIも用いています。',
    themes: [
      {
        name: 'Elm',
        link: 'https://elm-lang.org/',
      },
      {
        name: 'React',
        link: 'https://reactjs.org/',
      },
      {
        name: 'UI-Framework',
        link: 'https://semantic-ui.com/',
      },
    ],
  },
  {
    name: 'サイト構築',
    iconName: 'building outline',
    content: 'このサイトを作成するときのメモ等です。',
    themes: [
      {
        name: 'Gatsby',
        link: 'https://www.gatsbyjs.org/',
      },
      {
        name: 'React',
        link: 'https://reactjs.org/',
      },
      {
        name: 'UI-Framework',
        link: 'https://semantic-ui.com/',
      },
    ],
  },
  {
    name: 'サイト構築',
    iconName: 'browser',
    content:
      'アプリ側ではElmやReactを使用しています。画面の作成にはSemanticUIも用いています。',
    themes: [
      {
        name: 'Elm',
        link: 'https://elm-lang.org/',
      },
      {
        name: 'React',
        link: 'https://reactjs.org/',
      },
      {
        name: 'UI-Framework',
        link: 'https://semantic-ui.com/',
      },
    ],
  },
];

export interface SkillProp {
  iconName: SemanticICONS;
  name: string;
  content: string;
  link: string | null;
  linkDesk: string | null;
  count: number;
}

export const mySkills: SkillProp[] = [
  {
    iconName: 'tint',
    name: 'Elixir',
    content: 'サーバーにElixirを用いたものを開発しています。',
    link: 'https://elixir-lang.org/',
    linkDesk: '公式サイト',
    count: 0,
  },
  {
    iconName: 'rocket',
    name: 'Gatsby',
    content: 'React−GraphQL等を盛り込んだ静的サイトジェネレーターです。',
    link: 'https://www.gatsbyjs.org/',
    linkDesk: '公式サイト',
    count: 0,
  },
  {
    iconName: 'terminal',
    name: 'Elm',
    content: 'クライアントサイドで使用する関数型言語です。',
    link: 'https://elm-lang.org/',
    linkDesk: '公式サイト',
    count: 0,
  },
  {
    iconName: 'phoenix framework',
    name: 'Phoenix',
    content: 'Elixir上で動作するフレームワークです。',
    link: 'https://phoenixframework.org/',
    linkDesk: '公式サイト',
    count: 0,
  },
  {
    iconName: 'download',
    name: 'Markdown',
    content: '文書構造をテキストベースで簡単に記述できるドキュメント形式です。',
    link: 'https://daringfireball.net/projects/markdown/',
    linkDesk: '公式サイト',
    count: 0,
  },
  {
    iconName: 'certificate',
    name: 'Auth',
    content: '認証技術に関する話題です。',
    link: null,
    linkDesk: null,
    count: 0,
  },
  {
    iconName: 'docker',
    name: 'Container',
    content: 'DockerやKubernetes等のコンテナ技術に関する話題です。',
    link: null,
    linkDesk: null,
    count: 0,
  },
  {
    iconName: 'mobile alternate',
    name: 'Gadget',
    content: 'ガジェットに関する話題です',
    link: null,
    linkDesk: null,
    count: 0,
  },
  {
    iconName: 'magic',
    name: 'LifeHack',
    content: '物事を効率的に進める方法についての話題です。',
    link: null,
    linkDesk: null,
    count: 0,
  },
  {
    iconName: 'inbox',
    name: 'Misc',
    content: '広く一般的な話題です。',
    link: null,
    linkDesk: null,
    count: 0,
  },
  {
    iconName: 'music',
    name: 'Music',
    content: '音楽関連の話題です。',
    link: null,
    linkDesk: null,
    count: 0,
  },
  // {
  //   id: index++,
  //   iconName: 'pills',
  //   name: 'Elixir',
  //   content: 'サーバーにElixirを用いたものを開発しています。',
  //   link: 'dummyLink'
  // },
  // {
  //   id: index++,
  //   iconName: 'drivers license',
  //   name: 'Elm',
  //   content: 'サーバーにElixirを用いたものを開発しています。',
  //   link: 'dummyLink'
  // },
  // {
  //   id: index++,
  //   iconName: 'pills',
  //   name: 'Elixir',
  //   content: 'サーバーにElixirを用いたものを開発しています。',
  //   link: 'dummyLink'
  // },
  // {
  //   id: index++,
  //   iconName: 'drivers license',
  //   name: 'Elm',
  //   content: 'サーバーにElixirを用いたものを開発しています。',
  //   link: 'dummyLink'
  // },
];
