import React from 'react';
import { navigate } from 'gatsby';

import {
  Icon,
  Card,
  Container,
  Grid,
  Header,
  Label,
  // List,
  // Divider,
} from 'semantic-ui-react';
import { graphql, StaticQuery, Link } from 'gatsby';
// import {SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";

import {
  mySkills,
  SkillProp,
  // categories,
  // CategProp,
  // ThemeProp,
} from '../../Data/SkillsData';
import { TagCounter } from '../../libs/MapReduce';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
// import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

const Skill: React.FC<SkillProp> = ({
  iconName,
  name,
  content,
  count,
  linkDesk,
  link,
}) => {
  const colorStr: string = count > 0 ? 'blue' : 'grey';
  const tagColor: SemanticCOLORS = colorStr as SemanticCOLORS;
  const postsStr: string = count > 1 ? 'posts' : count > 0 ? 'post' : '';
  const urltag = name.toLowerCase();

  const handleIconClick = () => {
    navigate(`/tags/${urltag}`);
  };

  return (
    <Card>
      <Icon
        fitted
        name={iconName}
        size="huge"
        color="blue"
        link
        onClick={handleIconClick}
        className={'card-icon-pt-2'}
      />
      <Card.Content className={'card-content-mt-2'}>
        <Card.Header>
          <Link to={`/tags/${urltag}`} state={{ fromFeed: true }}>
            {name}
          </Link>
        </Card.Header>
        <Card.Description>{content}</Card.Description>

        {link && (
          <Label>
            <Card.Description>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkDesk}
              </a>
            </Card.Description>
          </Label>
        )}
      </Card.Content>
      <Card.Content extra>
        <Link to={`/tags/${urltag}`} state={{ fromFeed: true }} disabled={true}>
          <Label color={tagColor}>
            <Icon name="tag" />
            {count}
            {postsStr}
          </Label>
        </Link>
      </Card.Content>
    </Card>
  );
};

// const Category: React.FC<ThemeProp> = ({
//   id,
//   name,
//   iconName,
//   content,
//   themes,
//   tc,
// }) => {
//   return (
//     <Card key={id}>
//       <Icon fitted name={iconName} size="huge" color="blue" />
//       <Card.Content>
//         <Card.Header>
//           {/*<Link to={`/tags/${urltag}`} state={{ fromFeed: true }}>*/}
//           {name}
//           {/*</Link>*/}
//         </Card.Header>
//         <Card.Description>{content}</Card.Description>
//       </Card.Content>
//       <Card.Content extra>
//         <List divided relaxed>
//           {themes.map(({ name, link }, index: number) => {
//             const count = tc.getTagCount(name);
//             const colorStr: SemanticCOLORS = count > 0 ? 'blue' : 'grey';
//             const urltag = name.toLowerCase();
//             return (
//               <List.Item as="a" key={index}>
//                 <Label horizontal color={colorStr}>
//                   <Icon name="tag" />
//                   {name}
//                   <Label.Detail color="red">{count}</Label.Detail>
//                 </Label>
//               </List.Item>
//             );
//           })}
//         </List>
//       </Card.Content>
//     </Card>
//   );
// };

interface TagsQueryType {
  allMarkdownRemark: {
    edges: Post[];
  };
}

interface Post {
  node: {
    frontmatter: {
      tags: string[];
    };
  };
}

interface Posts {
  // edges: Post[];
  forEach: (callback: (post: Post) => void) => void;
}
const Skills: React.FC = () => {
  const tagsQuery = graphql`
    query {
      allMarkdownRemark(filter: { fields: { draft: { eq: false } } }) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={tagsQuery}
      render={(data: TagsQueryType) => {
        const posts: Posts = data.allMarkdownRemark.edges;
        let tc = new TagCounter();
        posts.forEach((post: Post) => {
          post.node.frontmatter.tags.forEach(tag => {
            tc.addTagCount(tag);
          });
        });
        return (
          <Container style={{ marginTop: 40 }}>
            <Header as="h1" textAlign="center" id="skills" color={'blue'}>
              テーマ一覧
            </Header>
            <Grid columns={3} stackable>
              {mySkills.map(
                ({ iconName, name, content, link, linkDesk }, index) => (
                  <Grid.Column key={index}>
                    <Skill
                      iconName={iconName}
                      name={name}
                      content={content}
                      link={link}
                      linkDesk={linkDesk}
                      count={tc.getTagCount(name)}
                    />
                  </Grid.Column>
                )
              )}
              {/*{categories.map(({ name, iconName, content, themes }, index) => (*/}
              {/*  <Grid.Column key={index}>*/}
              {/*    <Category*/}
              {/*      iconName={iconName}*/}
              {/*      name={name}*/}
              {/*      content={content}*/}
              {/*      themes={themes}*/}
              {/*      tc={tc}*/}
              {/*    />*/}
              {/*  </Grid.Column>*/}
              {/*))}*/}
            </Grid>
          </Container>
        );
      }}
    />
  );
};

export default Skills;
