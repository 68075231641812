export class MapReduce<T, U> {
  //
  //   private values: Map<T, U[]>;
  //
  //   constructor() {
  //
  //   }
  //
  //   public emit = (key: T, value: U):  boolean => {
  //     return true;
  //
  //   };
  //
}

export class TagCounter {
  private data: Map<string, number>;

  public constructor() {
    this.data = new Map<string, number>();
  }

  private getCurrentCount(tag: string): number {
    let data = this.data;
    let result = 0;
    if (data.has(tag)) {
      const tmp: number | undefined = data.get(tag);
      if (tmp) {
        result = tmp;
      }
    }
    return result;
  }

  public addTagCount(tag: string): void {
    let data = this.data;
    data.set(tag, this.getCurrentCount(tag) + 1);
  }

  public getTagCount(tag: string): number {
    return this.getCurrentCount(tag);
  }

  public getData(): Map<string, number> {
    return this.data;
  }
}
