import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Card, Label, Icon } from 'semantic-ui-react';
import Layout from '../components/layout/layout';

const TagList = ({ data }: any): React.ReactNode => {
  const postsCount: number = data.allMarkdownRemark.totalCount;
  const postsStr: string =
    postsCount > 1 ? 'posts' : postsCount > 0 ? 'post' : 'post';

  return (
    <Layout>
      <Container text>
        <h1>Recent Posts</h1>
        <h4>
          {postsCount}
          {postsStr}
        </h4>
        <Card.Group>
          {data.allMarkdownRemark.edges.map(({ node }: any) => (
            <Card fluid key={node.id}>
              <Card.Header as="h3" style={{ padding: '0.5rem' }}>
                <Link to={`/posts${node.fields.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </Card.Header>
              <Card.Content>{node.frontmatter.description}</Card.Content>
              <Card.Description>{node.excerpt}</Card.Description>
              <Card.Content extra>
                {node.frontmatter.tags.map((tag: string, id: number) => (
                  <Label
                    as="a"
                    href={`/tags/${tag.toLowerCase()}/`}
                    key={id}
                    color="orange"
                  >
                    <Icon name="tag" />
                    {tag}
                  </Label>
                ))}
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { draft: { eq: false } }
        frontmatter: { tags: { in: [$tag] } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            description
            tags
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`;

export default TagList;
