// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-success-tsx": () => import("./../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-about-site-tsx": () => import("./../src/templates/AboutSite.tsx" /* webpackChunkName: "component---src-templates-about-site-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-posts-list-tsx": () => import("./../src/templates/PostsList.tsx" /* webpackChunkName: "component---src-templates-posts-list-tsx" */),
  "component---src-templates-tag-list-tsx": () => import("./../src/templates/TagList.tsx" /* webpackChunkName: "component---src-templates-tag-list-tsx" */)
}

